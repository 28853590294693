import { ref, reactive } from 'vue';
import apiClient from '@/services/api';
import { useUserStore } from '@/stores/userStore';
import router from '@/router';

export default function useUser() {
    const userStore = useUserStore();
    const error = ref(null);
    const loading = ref(false);

    const profile = reactive({
        user: {
            id: null,
            full_name: '',
            username: '',
            email: '',
            avatar: null,
            roles: [],
            created_at: '',
            unread_notifications_count: 0,
        },
        test_stats: {
            completed_tests: 0,
            in_progress_tests: 0,
            success_rate: 0,
            average_grade: 0,
            average_time_per_test: 0,
        },
        rank: 0,
    });

    const fetchUser = async () => {
        try {
            loading.value = true;
            const response = await apiClient.get('/user');
            userStore.setUser(response.data.user);
            // Populăm profilul cu datele utilizatorului curent
            Object.assign(profile.user, response.data.user);
        } catch (err) {
            userStore.clearUser();
            localStorage.removeItem('token');
            router.push('/login');
            error.value = 'USER_NOT_FOUND';
        } finally {
            loading.value = false;
        }
    };

    const fetchProfile = async (userId) => {
        loading.value = true;
        error.value = null;

        try {
            const response = await apiClient.get(`/profile/${userId}`);
            if (response.data.success === 'USER_FETCHED') {
                Object.assign(profile.user, response.data.user);
                Object.assign(profile.test_stats, response.data.test_stats);
                profile.rank = response.data.rank;
            } else {
                throw new Error('Unexpected response format');
            }
        } catch (err) {
            console.error('Failed to fetch user profile', err);
            error.value = 'AN_ERROR_OCCURRED';
        } finally {
            loading.value = false;
        }
    };

    return {
        error,
        loading,
        profile,
        fetchUser,
        fetchProfile,
    };
}
