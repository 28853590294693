<template>
    <div v-if="isLoading" class="loading-spinner">
        <div class="spinner"></div>
        <span>Se încarcă..</span>
    </div>
    <div v-else>
        <AuthLayout v-if="!isAuthenticated">
            <router-view></router-view>
        </AuthLayout>
        <MainLayout v-else>
            <router-view></router-view>
        </MainLayout>
    </div>
    
    <ui-popup-message
        v-for="notification in notifications"
        :key="notification.id"
        :type="notification.type"
        :code="notification.code"
    />
</template>

<script setup>
import { computed, onMounted, ref } from 'vue';
import { useUserStore } from '@/stores/userStore';
import useUser from '@/composables/useUser';
import AuthLayout from '@/layouts/Auth.vue';
import MainLayout from '@/layouts/Main.vue';
import { storeToRefs } from 'pinia';
import { useNotificationStore } from '@/stores/notificationStore';
import UiPopupMessage from '@/components/Ui/PopupMessage.vue';

const userStore = useUserStore();
const { fetchUser } = useUser();
const isLoading = ref(true);

const isAuthenticated = computed(() => !!userStore.user);

onMounted(async () => {
    const token = localStorage.getItem('token');
    if (token && !userStore.user) {
        await fetchUser();
    }
    isLoading.value = false;
});

const notificationStore = useNotificationStore();
const { notifications } = storeToRefs(notificationStore);

</script>

<style>
#app {
    font-family: 'Roboto', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.loading-spinner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    font-size: 1.1em;
    color: #666;
}

.spinner {
    border: 8px solid #f3f3f3;
    border-top: 8px solid #14A085;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
    margin-bottom: 10px;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
</style>
