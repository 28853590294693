<template>
    <aside
        :class="[
            'bg-white w-45 h-screen shadow-md transition-all duration-300 ease-in-out fixed z-20',
            isOpen ? 'translate-x-0' : '-translate-x-full',
            'md:translate-x-0 md:static'
        ]"
    >
        <div class="p-4 h-full flex flex-col">
            <!-- Close button for mobile -->
            <button @click="$emit('close')" class="absolute top-3 right-3 text-gray-500 md:hidden">
                <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
                </svg>
            </button>

            <!-- Logo -->
            <div class="flex items-center mb-8 mt-4 md:mt-0 cursor-pointer" @click="$router.push('/statistici')">
                <img src="@/assets/exports/logo-transparent.png" alt="Logo" class="h-10 mr-2">
            </div>
            
            <!-- Navigation Items -->
            <nav class="flex-grow">
                <ul>
                    <li class="mb-2">
                        <router-link to="/statistici" @click="$emit('close')"
                            class="flex items-center p-2 text-gray-700 hover:bg-gray-200 rounded mt-2"
                            active-class="bg-gray-200 text-success-600"
                        >
                            <ChartPieIcon class="w-7 h-7 me-2 text-weight-bold" size="30" />
                            Statistici
                        </router-link>
                        
                        <router-link to="/teste" @click="$emit('close')"
                            class="flex items-center p-2 text-gray-700 hover:bg-gray-200 rounded mt-2"
                            active-class="bg-gray-200 text-success-600"
                        >
                            <BookOpenIcon class="w-7 h-7 me-2 text-weight-bold" />
                            Teste disponibile
                        </router-link>
                        
                        <router-link to="/testele-mele" @click="$emit('close')"
                            class="flex items-center p-2 text-gray-700 hover:bg-gray-200 rounded mt-2"
                            active-class="bg-gray-200 text-success-600"
                        >
                            <CheckBadgeIcon class="w-7 h-7 me-2 text-weight-bold" />
                            Teste susținute
                        </router-link>
                        
                        <router-link to="/setari" @click="$emit('close')"
                            class="flex items-center p-2 text-gray-700 hover:bg-gray-200 rounded mt-2"
                            active-class="bg-gray-200 text-success-600"
                        >
                            <Cog8ToothIcon class="w-7 h-7 me-2 text-weight-bold" />
                            Setări profil
                        </router-link>
                        
                        <router-link to="/notificari" @click="$emit('close')"
                            class="flex items-center p-2 text-gray-700 hover:bg-gray-200 rounded mt-2 relative"
                            active-class="bg-gray-200 text-success-600"
                        >
                            <BellIcon class="w-7 h-7 me-2 text-weight-bold" />
                            Notificări
                            <span v-if="unreadNotificationsCount > 0"
                                :class="[
                                    'absolute top-1/2 transform translate-x-1/2 -translate-y-1/2 bg-red-500 text-white rounded-full flex items-center justify-center font-bold',
                                    unreadNotificationsCount > 9 ? 'right-4 w-5 h-5 text-xs' : 'right-3 w-4 h-4 text-xs'
                                ]">
                                {{ unreadNotificationsCount }}
                            </span>
                        </router-link>

                        <router-link to="/profilul-meu" @click="$emit('close')"
                            class="flex items-center p-2 text-gray-700 hover:bg-gray-200 rounded mt-2"
                            active-class="bg-gray-200 text-success-600"
                        >
                            <!-- Avatar -->
                            <img :src="avatarUrl" alt="User avatar" class="w-7 h-7 me-2 rounded-full">
                            {{ fullName }}
                        </router-link>
                        
                    </li>
                </ul>
            </nav>

            <!-- Bottom section -->
            <div class="mt-auto">
                <hr class="my-4 border-gray-200" />
                <button @click="logout(), $emit('close')"
                    class="flex items-center p-2 text-gray-700 hover:bg-gray-200 rounded w-full">
                    <ArrowLeftStartOnRectangleIcon class="w-7 h-7 me-2 text-weight-bold" />
                    Deconectare
                </button>
            </div>
        </div>
    </aside>
</template>

<script setup>
import { computed, defineProps } from 'vue';
import useAuth from '@/composables/useAuth';
import { 
    ChartPieIcon,
    ClipboardDocumentListIcon,
    CheckBadgeIcon,
    Cog8ToothIcon,
    ArrowLeftStartOnRectangleIcon,
    BookOpenIcon,
    BellIcon
} from '@heroicons/vue/24/outline';
import { useUserStore } from '@/stores/userStore';

const { logout } = useAuth();
const userStore = useUserStore();

defineProps({
    isOpen: {
        type: Boolean,
        default: false
    }
});

const unreadNotificationsCount = computed(() => userStore.user.unread_notifications_count);

const fullName = computed(() => userStore.user.full_name);

const avatarUrl = computed(() => {
    if (userStore.user?.avatar?.original_url) {
        return userStore.user.avatar.original_url;
    }
    return require('@/assets/exports/default-avatar.svg');
});

</script>

<style scoped>
</style>
