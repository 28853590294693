import { createPinia, defineStore } from 'pinia';

export const useUserStore = defineStore('user', {
    state: () => ({
        user: null,
    }),
    actions: {
        getUser() {
            return this.user;
        },
        setUser(userData) {
            this.user = userData;
        },
        clearUser() {
            this.user = null;
        },
    },
});

const pinia = createPinia();
export default pinia;
